import { Route } from '@angular/router';

export const usersRoutes: Route[] = [
  {
    path: 'list',
    loadComponent: () => import('./users-list-page/users-list-page.component').then( m => m.UsersListPageComponent),
    children: [
      {
        path: 'create',
        loadComponent: () => import('./add-user-modal/add-user-modal.component').then( m => m.AddUserModalComponent)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
