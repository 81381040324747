import { Table } from 'primeng/table';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { SplitButtonModule } from 'primeng/splitbutton';
import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { I18nModule } from '@mgm-frontend-monorepo/transloco';

@Component({
  selector: 'ui-export-table-button',
  standalone: true,
  imports: [
    CommonModule,
    SplitButtonModule,
    I18nModule
  ],
  template: `
    <p-splitButton
      [label]="'export' | transloco"
      icon="pi pi-file"
      (onClick)="table.exportCSV()"
      [model]="exportButtons"
      styleClass="export-button"
    ></p-splitButton>
  `,
  styles: [`
    ::ng-deep .export-button {
      height: 46px;
    }
  `]
})
export class ExportTableButtonComponent implements OnDestroy {

  @Input() table!: Table;

  exportButtons: MenuItem[] = [];

  langSub?: Subscription;

  constructor(
    private translocoService: TranslocoService,
    private translocoPipe: TranslocoPipe
  ) {

    this.langSub = this.translocoService.langChanges$.subscribe(async () => {

      this.exportButtons = [
        {
          label: this.translocoPipe.transform('exportOnlySelected'),
          icon: 'pi pi-check-square',
          command: () => {
            this.table?.exportCSV({selectionOnly:true})
          }
        }
      ];

    });

    this.exportButtons = [
      {
        label: this.translocoPipe.transform('exportOnlySelected'),
        icon: 'pi pi-check-square',
        command: () => {
          this.table?.exportCSV({selectionOnly:true})
        }
      }
    ];


  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

}
