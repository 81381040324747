import { ViewChild } from '@angular/core';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountriesService } from '@mgm-frontend-monorepo/shared';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-phone-input',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    InputTextModule,
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneInputComponent,
    },
  ],
  template: `
    <span class="w-full p-input-icon-right">
      <i class="pi pi-phone" style="z-index: 1000"></i>
      <div class="p-inputgroup">
        <div style="min-width: 120px">
          <p-dropdown
            [ngClass]="{'ng-invalid ng-touched': touched && invalid}"
            [disabled]="disabled"
            (onChange)="setCountryPhoneCode($event)"
            appendTo="body"
            [options]="countriesService.countries"
            optionValue="code"
            optionLabel="country"
            [filter]="true"
            filterBy="country,code"
            [ngModel]="code"
            styleClass="no-border-right-p-dropdown"
          >
            <ng-template pTemplate="selectedItem" let-country>
              <div class="flex align-items-center gap-2">
                <img
                  src="../../../../assets/imgs/flag_placeholder.png"
                  [class]="'flag flag-' + country.iso.toLowerCase()"
                />
                <div>+{{ country.code }}</div>
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex align-items-center gap-2">
                <img
                  src="../../../../assets/imgs/flag_placeholder.png"
                  [class]="'flag flag-' + country.iso.toLowerCase()"
                />
                <div>{{ country.country }} (+{{ country.code }})</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <input [tabindex]="tabindex" #input [placeholder]="placeholder" [ngClass]="{'ng-invalid ng-touched': touched && invalid}" [disabled]="disabled" type="phone" pInputText (input)="onInputChange($event)" />
      </div>
    </span>
  `,
  styles: [
    `
      ::ng-deep .no-border-right-p-dropdown {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100% !important;
      }
    `
  ]
})
export class PhoneInputComponent implements ControlValueAccessor {

  @ViewChild('input') input: any;

  @Input() code = '32';
  //@Input() required = false;
  @Input() touched: boolean | null = false;
  @Input() disabled = false;
  @Input() invalid: boolean | null = false;
  @Input() placeholder = '';
  @Input() tabindex = '';

  phoneNumber = '+32 ';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (phoneNumber: string) => {};


  constructor(public countriesService: CountriesService) {

    this.phoneNumber = '+' + this.code;

  }

  onInputChange(event: any) {

    this.markAsTouched();

    if (event.target.value === '') {
      this.phoneNumber = '';
    } else {
      this.phoneNumber = '+' + this.code + ' ' + event.target.value;
    }

    this.onChange(this.phoneNumber);

  }

  setCountryPhoneCode(event: {value: string}) {
    this.code = event.value;
    this.phoneNumber = '+' + event.value + ' ' + (this.phoneNumber.split(' ')[1] ? this.phoneNumber.split(' ')[1] : '');
    this.onChange(this.phoneNumber);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(value: any) {

    if (value) {

      const splittedVal = value.split(' ');

      if (splittedVal.length === 2) {

        const prefix = splittedVal[0].replace('+', '');

        this.input.nativeElement.value = splittedVal[1];

        // Set the country code
        this.setCountryPhoneCode({value: prefix});

        this.phoneNumber = value;

        // Emit change event to update the value in the parent component
        this.onChange(this.phoneNumber);

      }

    }

  }


  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
