import { ErrorLabelComponent } from '../error-label/error-label.component';
import { I18nModule } from '@mgm-frontend-monorepo/transloco';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ui-login-form',
  standalone: true,
  imports: [CommonModule, CardModule, InputTextModule, ButtonModule, FormsModule, I18nModule, ErrorLabelComponent],
  template: `
    <p-card styleClass="shadow-2">
      <h1 class="text-5xl text-center">{{ 'logIn' | transloco }}</h1>


      <form (ngSubmit)="onFormSubmit.emit(form)" #form="ngForm">
        <div class="p-2" *ngIf="type === 'email'">
          <span class="p-float-label p-input-icon-left w-full">
            <i class="pi pi-envelope"></i>
            <input
              type="email"
              pInputText
              class="w-full"
              name="email"
              ngModel
              required
              email
              id="email"
              #email="ngModel"
            />
            <label>{{ 'email' | transloco }}</label>
          </span>
          <ui-error-label [status]="email.touched && email.invalid" [message]="(email.errors && email.errors['required'] ? 'errorMessages.emailRequired' : 'errorMessages.emailInvalid') | transloco"></ui-error-label>
        </div>
        <div class="p-2" *ngIf="type === 'username'">
          <span class="p-float-label p-input-icon-left w-full">
            <i class="pi pi-user"></i>
            <input
              pInputText
              class="w-full"
              name="username"
              ngModel
              required
              #username="ngModel"
            />
            <label>{{ 'username' | transloco }}</label>
          </span>
          <ui-error-label [status]="username.touched && username.invalid" [message]="'errorMessages.usernameRequired' | transloco"></ui-error-label>
        </div>
        <div class="p-2">
          <span class="p-float-label p-input-icon-left w-full">
            <i class="pi pi-key"></i>
            <input
              type="password"
              pInputText
              class="w-full"
              name="password"
              ngModel
              required
              #password="ngModel"
            />
            <label for="inputtext">{{ 'password' | transloco }}</label>
          </span>
          <ui-error-label [status]="password.touched && password.invalid" [message]="'errorMessages.passwordRequired' | transloco"></ui-error-label>
        </div>

        <div class="text-center m-2">
          <p-button [disabled]="form.invalid ? true : false" [loading]="isLoading" type="submit" [label]="'logIn' | transloco"></p-button>
        </div>

      </form>
    </p-card>
  `,
})
export class LoginFormComponent {

  @Input() isLoading = false;

  // Input type (email or username)
  @Input() type: 'email' | 'username' = 'email';

  @Output() onFormSubmit = new EventEmitter();



}
