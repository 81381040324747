import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countries: any = [];

  countriesChanges$ = new Subject();

  constructor(
    private translocoService: TranslocoService,
    private http: HttpClient,
  ) {

    translocoService.langChanges$.subscribe(async (l) => {

      this.http.get(`/assets/i18n/countries-${l}.json`).subscribe((r) => {
        this.countries = r

        this.countriesChanges$.next(null);

      });

    });

  }

  getCountryByCountryCode(countryCode: string) {
    return this.countries.find((c: {iso: string}) => c.iso === countryCode)?.country;
  }

}
