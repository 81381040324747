import { DropdownModule } from 'primeng/dropdown';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { I18nModule } from '@mgm-frontend-monorepo/transloco';
import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorLabelComponent } from '../error-label/error-label.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AutoComplete, AutoCompleteModule } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, tap } from 'rxjs';
import { CountriesService } from '@mgm-frontend-monorepo/shared';

@Component({
  selector: 'ui-address-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    InputTextModule,
    ButtonModule,
    PanelModule,
    ErrorLabelComponent,
    DropdownModule,
    I18nModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AddressInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AddressInputComponent,
    },
  ],
  template: `
    <form [formGroup]="form">

      <div class="flex gap-2">
        <div class="p-inputgroup w-full mb-5 ">
          <span [ngClass]="{'border-red': validate() && touched}" class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <p-autoComplete [tabIndex]="tabindex" #text [ngClass]="{'ng-invalid ng-touched': touched && validate()}" (onSelect)="form.markAsTouched();form.setValue($event)" styleClass="w-full" [placeholder]="'searchAddress' | transloco " [showEmptyMessage]="true" [suggestions]="addresses" (completeMethod)="searchAddress($event)" [minLength]="6" field="text"></p-autoComplete>
        </div>
        <button style="margin-top: 1px;" (click)="form.reset(); " pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"></button>
      </div>


      <div class="grid">
        <div class="col-3">
          <span class="p-float-label">
            <input type="text" formControlName="unitNumber" pInputText class="w-full"/>
            <label>{{ 'unitNumber' | transloco }}</label>
          </span>
        </div>

        <div class="col-9">
          <span class="p-float-label">
            <input type="text" formControlName="street" pInputText class="w-full"/>
            <label>{{ 'street' | transloco }}</label>
          </span>
          <ui-error-label [status]="form.controls['street'].touched && form.controls['street'].invalid" [message]="'errorMessages.addressStreetRequired' | transloco"></ui-error-label>
        </div>

        <div class="col-3">
          <span class="p-float-label">
            <input type="text" formControlName="postCode" pInputText class="w-full"/>
            <label>{{ 'postCode' | transloco }}</label>
          </span>
          <ui-error-label [status]="form.controls['postCode'].touched && form.controls['postCode'].invalid" [message]="'errorMessages.addressPostCodeRequired' | transloco"></ui-error-label>
        </div>

        <div class="col-5">
          <span class="p-float-label">
            <input type="text" formControlName="city" pInputText class="w-full"/>
            <label>{{ 'city' | transloco }}</label>
          </span>
          <ui-error-label [status]="form.controls['city'].touched && form.controls['city'].invalid" [message]="'errorMessages.addressCityRequired' | transloco"></ui-error-label>

        </div>

        <div class="col-4">
          <span class="p-float-label w-full">
            <p-dropdown [placeholder]="'country' | transloco" (onChange)="setCountryCode($event)" formControlName="country" appendTo="body" styleClass="w-full" [options]="countriesService.countries" optionValue="country" optionLabel="country" [filter]="true" filterBy="country">
              <ng-template pTemplate="selectedItem" let-country>
                <div class="flex align-items-center gap-2" *ngIf="form.controls['country'].value">
                    <img src="../../../../assets/imgs/flag_placeholder.png" [class]="'flag flag-' + country.iso.toLowerCase()" />
                    <div>{{country.country}}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <img src="../../../../assets/imgs/flag_placeholder.png" [class]="'flag flag-' + country.iso.toLowerCase()" />
                    <div>{{country.country}}</div>
                </div>
                </ng-template>
            </p-dropdown>
            <label>{{ 'country' | transloco }}</label>
          </span>

          <ui-error-label [status]="form.controls['country'].touched && form.controls['country'].invalid" [message]="'errorMessages.addressCountryRequired' | transloco"></ui-error-label>

        </div>
      </div>

      <div class="hidden">
        <input formControlName="longitude"/>
        <input formControlName="latitude"/>
        <input #countryCode formControlName="countryCode"/>
        <input formControlName="text"/>
      </div>
      </form>
  `,
  styles: [
    `
      :host ::ng-deep {

        .no-bg .p-panel {
          box-shadow: none !important;
        }

        .no-bg .p-panel .p-panel-content {
          background-color: transparent !important;
          padding: 0 !important;
        }

        .p-panel-content {
          padding: 10px !important;
        }
        .p-autocomplete-input {
          width: 100%;
        }

        .p-autocomplete-loader {
          z-index: 200;
        }
      }

      .border-red {
        border-color: var(--error-color) !important;
      }
    `
  ],
})
export class AddressInputComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {

  onFormChangeSub?: Subscription;

  addresses = [];

  isLoadingAddressSearch = false;

  @Input() required = false;
  @Input() environment = {backendUrl: ''};
  @Input() tabindex = '';

  touched: boolean | null = false;

  @ViewChild('text') text: AutoComplete | undefined;

  form: FormGroup;

  countriesChangeSub: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  constructor(
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private http: HttpClient,
    public countriesService: CountriesService
  ) {



    this.form = this.fb.group({
      unitNumber: [null],
      street: [null],
      city: [null],
      postCode: [null],
      country: [null],
      countryCode: [null],
      longitude: [null],
      latitude: [null],
      text: [null],
    });


    this.countriesChangeSub = countriesService.countriesChanges$.subscribe(async () => {

      const c = countriesService.countries.find((c: any) => c.iso === this.form.controls['countryCode'].value);

      if (c) {
        this.form.controls['country'].setValue(c.country);
      }

    });
  }

  ngAfterViewInit() {

    this.form = this.fb.group({
      unitNumber: [null],
      street: [null, this.required ? [Validators.required] : []],
      city: [null, this.required ? [Validators.required] : []],
      postCode: [null, this.required ? [Validators.required] : []],
      country: [null, this.required ? [Validators.required] : []],
      countryCode: [null, this.required ? [Validators.required] : []],
      longitude: [null, this.required ? [Validators.required] : []],
      latitude: [null, this.required ? [Validators.required] : []],
      text: [null],
    });

    setTimeout(() => {
      // Set the unit number to null if empty (this is needed because on init the value is "", and null is needed for the backend, so we update the form in order to trigger the onChange function)
      if (!this.form.controls['unitNumber'].value && !this.required) {
        this.form.patchValue({unitNumber: null});
      }
    }, 100);

  }


  /**
   * Search an address
   *
   * @param e Event
   */
  async searchAddress(e: any) {

    this.http
      .get(this.environment.backendUrl + '/addresses/searchByText?addressText=' + e.query + '&lang=' + this.translocoService.getActiveLang()).pipe(
        tap((result: any) => {
          this.addresses = result;
        })
      ).subscribe();

  }

  /**
   * Set countryCode on country select
   * @param e event
   */
  setCountryCode(e: any) {

    this.form.controls['countryCode'].setValue(
      this.countriesService.countries.find((c: any) => c.country === e.value)?.iso
    );
  }

  ngOnDestroy() {
    this.onFormChangeSub?.unsubscribe();
    this.countriesChangeSub.unsubscribe();
  }

  registerOnChange(onChange: any) {

    // On values changes update address text
    this.onFormChangeSub = this.form.valueChanges.subscribe((a) => {

      let formattedAddress = '';

      if (a.street) {
        formattedAddress += a.street;
      }
      if (a.unitNumber) {
        formattedAddress += ' ' + a.unitNumber;
      }
      if (a.city) {
        formattedAddress += ', ' + a.city;
      }
      if (a.postCode) {
        formattedAddress += ', ' + a.postCode;
      }
      if (a.country) {
        formattedAddress += ', ' + a.country;
      }

      // If all fields are null or empty then onChange null
      if (!a.street && !a.unitNumber && !a.city && !a.postCode && !a.country) {
        onChange(null);
      } else {
        this.form.controls['text'].setValue(formattedAddress, {emitEvent: false});
        onChange(this.form.value);
      }



      this.markAsTouched();


      if (this.text) {
        this.text.inputEL.nativeElement.value = formattedAddress;
      }


    })

  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(value: any) {

    if (value) {

      this.form.patchValue(value, {emitEvent: false});

      // If text provided then set autocomplete value and remove blockui
      if (this.form.value.text && this.text) {

        this.text.inputEL.nativeElement.value = this.form.value.text;

      }
    }
  }

  validate() {
    if (this.form.valid) {
      return null;
    }

    let found = false;

    // Check if all inputs are null (this means required error otherwise is invalid error)
    for (const key of Object.keys(this.form.value)) {

      if(key !== 'unitNumber' && this.form.value[key]) {
        found = true;
        break;
      }

    }


    return found ? { invalid: true } : this.required ? { required: true } : null;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
