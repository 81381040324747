import { ViewChild } from '@angular/core';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountriesService } from '@mgm-frontend-monorepo/shared';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-plate-number-input',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    InputTextModule,
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PlateNumberInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PlateNumberInputComponent,
    },
  ],
  template: `
      <div class="p-inputgroup">
        <div style="min-width: 80px">
          <p-dropdown
            [ngClass]="{'ng-invalid ng-touched': touched && invalid}"
            [disabled]="disabled"
            (onChange)="setCountryCode($event)"
            appendTo="body"
            [options]="countriesService.countries"
            optionValue="iso"
            optionLabel="country"
            [filter]="true"
            filterBy="country"
            [ngModel]="countryCode"
            styleClass="no-border-right-p-dropdown"
          >
            <ng-template pTemplate="selectedItem" let-country>
              <div class="flex align-items-center gap-2">
                <img
                  src="../../../../assets/imgs/flag_placeholder.png"
                  [class]="'flag flag-' + country.iso.toLowerCase()"
                />
              </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
              <div class="flex align-items-center gap-2">
                <img
                  src="../../../../assets/imgs/flag_placeholder.png"
                  [class]="'flag flag-' + country.iso.toLowerCase()"
                />
                <div>{{ country.country }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <input maxlength="20" [ngModel]="plateNumber" [tabindex]="tabindex" #input [placeholder]="placeholder" [ngClass]="{'ng-invalid ng-touched': touched && invalid}" [disabled]="disabled" type="text" pInputText (input)="onInputChange($event)" />
      </div>
  `,
  styles: [
    `
      ::ng-deep .no-border-right-p-dropdown {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 100% !important;
      }
    `
  ]
})
export class PlateNumberInputComponent implements ControlValueAccessor {

  @ViewChild('input') input: any;

  @Input() countryCode = 'BE';
  @Input() touched: boolean | null = false;
  @Input() disabled = false;
  @Input() invalid: boolean | null = false;
  @Input() placeholder = '';
  @Input() tabindex = '';
  @Input() required = false;

  plateNumber = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (obj: any) => {};


  constructor(public countriesService: CountriesService) {}

  onInputChange(event: any) {

    this.markAsTouched();

    this.plateNumber = event.target.value;

    this.onChange({plateNumber: this.plateNumber, countryCode: this.countryCode});

  }

  setCountryCode(event: {value: string}) {

    this.markAsTouched();

    this.countryCode = event.value;

    this.onChange({plateNumber: this.plateNumber, countryCode: this.countryCode});

  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(value: any) {

    if (value) {

      if (value.countryCode) {
        this.countryCode = value.countryCode;
      }

      this.plateNumber = value.plateNumber;

    }

  }

  validate() {

    if (this.required && !this.plateNumber) {
      return {required: true}
    }

    return null;
  }


  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
