import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { ViewChild, OnDestroy } from '@angular/core';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@mgm-frontend-monorepo/transloco';

@Component({
  selector: 'ui-fuel-type-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    I18nModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FuelTypeDropdownComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: FuelTypeDropdownComponent,
    },
  ],
  template: `
      <p-dropdown
        [ngClass]="{'ng-invalid ng-touched': touched && invalid}"
        [disabled]="disabled"
        (onChange)="setFuelType($event)"
        appendTo="body"
        [options]="fuelTypes"
        optionValue="value"
        optionLabel="label"
        [ngModel]="fuelType"
        [filter]="true"
        filterBy="label"
        styleClass="w-full"
        [placeholder]="placeholder"
      ></p-dropdown>
  `,
})
export class FuelTypeDropdownComponent implements ControlValueAccessor, OnDestroy {

  @ViewChild('input') input: any;

  @Input() touched: boolean | null = false;
  @Input() disabled = false;
  @Input() invalid: boolean | null = false;
  @Input() placeholder = '';
  @Input() tabindex = '';
  @Input() required = false;

  fuelTypes: {label: string, value: string}[] = [];
  fuelType = '';

  langSub: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (obj: any) => {};


  constructor(private translocoService: TranslocoService) {

    // Translate the fuel types
    this.setFuelTypes();

    this.langSub = this.translocoService.langChanges$.subscribe(() => {

      this.setFuelTypes();

    });

  }

  ngOnDestroy(): void {

    this.langSub.unsubscribe();

  }

  setFuelTypes() {

    this.fuelTypes = [
      {label: this.translocoService.translate('fuelTypes.PETROL'), value: 'PETROL'},
      {label: this.translocoService.translate('fuelTypes.DIESEL'), value: 'DIESEL'},
      {label: this.translocoService.translate('fuelTypes.MIXED'), value: 'MIXED'},
      {label: this.translocoService.translate('fuelTypes.HYDROGEN'), value: 'HYDROGEN'},
      {label: this.translocoService.translate('fuelTypes.CNG'), value: 'CNG'},
      {label: this.translocoService.translate('fuelTypes.ELECTRIC'), value: 'ELECTRIC'},
      {label: this.translocoService.translate('fuelTypes.LPG'), value: 'LPG'},
      {label: this.translocoService.translate('fuelTypes.PETROL_ELECTRIC'), value: 'PETROL_ELECTRIC'},
      {label: this.translocoService.translate('fuelTypes.DIESEL_ELECTRIC'), value: 'DIESEL_ELECTRIC'},
      {label: this.translocoService.translate('fuelTypes.PETROL_CNG'), value: 'PETROL_CNG'},
      {label: this.translocoService.translate('fuelTypes.PETROL_LNG'), value: 'PETROL_LNG'},
      {label: this.translocoService.translate('fuelTypes.PETROL_LPG'), value: 'PETROL_LPG'},
      {label: this.translocoService.translate('fuelTypes.PETROL_ETHANOL'), value: 'PETROL_ETHANOL'},
      {label: this.translocoService.translate('fuelTypes.PETROL_METHANOL'), value: 'PETROL_METHANOL'},
      {label: this.translocoService.translate('fuelTypes.DIESEL_LPG'), value: 'DIESEL_LPG'},
      {label: this.translocoService.translate('fuelTypes.PETROL_ETHANOL_ELECTRIC'), value: 'PETROL_ETHANOL_ELECTRIC'},
      {label: this.translocoService.translate('fuelTypes.ETHANOL'), value: 'ETHANOL'},
      {label: this.translocoService.translate('fuelTypes.PETROL_ETHANOL_CNG'), value: 'PETROL_ETHANOL_CNG'},
      {label: this.translocoService.translate('fuelTypes.UNKNOWN'), value: 'UNKNOWN'},
    ]

  }

  setFuelType(event: {value: string}) {

    this.markAsTouched();

    this.fuelType = event.value;

    this.onChange(event.value);

  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(value: any) {

    if (value) {

      this.fuelType = value;

    }

  }

  validate() {

    if (this.required && !this.fuelType) {
      return {required: true}
    }

    return null;
  }


  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
