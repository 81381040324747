import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, ThemeSwitcherComponent],
  providers: [],
  exports: [ThemeSwitcherComponent],
})
export class ThemeModule {}
