import { I18nModule } from '@mgm-frontend-monorepo/transloco';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';


@Component({
  selector: 'ui-customer-type-tag',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    I18nModule
  ],
  template: `
    <p-tag
      [value]="'customerTypes.' + type | transloco"
      [severity]="
        type === 'PRIVATE' ? 'info' :
        'help'
      "
      [icon]="
        type === 'PRIVATE' ? 'pi pi-user' :
        'pi pi-building'
      "
    ></p-tag>

  `,
  styles: [],
})
export class CustomerTypeTagComponent {

  @Input() type!: string;
}
