import { Route } from '@angular/router';
import { garagesRoutes } from './garages/garages.routes';
import { ordersRoutes } from './orders/orders.routes';
import { usersRoutes } from './users/users.routes';

export const pagesRoutes: Route[] = [
  {
    path: 'overview',
    loadComponent: () => import('./overview/overview.component').then( m => m.OverviewComponent)
  },
  {
    path: 'account',
    loadComponent: () => import('./account/account.component').then(m => m.AccountComponent),
  },
  {
    path: 'users',
    loadComponent: () => import('./users/users.component').then( m => m.UsersComponent),
    children: usersRoutes
  },
  {
    path: 'garages',
    loadComponent: () => import('./garages/garages.component').then( m => m.GaragesComponent),
    children: garagesRoutes
  },
  {
    path: 'orders',
    loadComponent: () => import('./orders/orders.component').then( m => m.OrdersComponent),
    children: ordersRoutes
  },
  {
    path: '**',
    redirectTo: 'overview',
    pathMatch: 'full'
  },
];
