import { Route } from '@angular/router';

export const garagesRoutes: Route[] = [
  {
    path: 'list',
    loadComponent: () => import('./garages-list-page/garages-list-page.component').then( m => m.GaragesListPageComponent),
    children: [
      {
        path: 'create',
        loadComponent: () => import('./add-garage-modal/add-garage-modal.component').then( m => m.AddGarageModalComponent)
      },
    ]
  },
  {
    path: ':garageId',
    loadComponent: () => import('./garage-page/garage-page.component').then( m => m.GaragePageComponent),
    children: [
      {
        path: 'info',
        loadComponent: () => import('./garage-page/garage-info-tab/garage-info-tab.component').then( m => m.GarageInfoTabComponent)
      },
      {
        path: 'employees',
        loadComponent: () => import('./garage-page/employees-tab/employees-tab.component').then( m => m.EmployeesTabComponent),
        children: [
          {
            path: 'create',
            loadComponent: () => import('./garage-page/employees-tab/add-employee-modal/add-employee-modal.component').then( m => m.AddEmployeeModalComponent)
          },
        ]
      },
      {
        path: '',
        redirectTo: 'info',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: ':garageId/employees/:employeeId',
    loadComponent: () => import('./garage-page/employees-tab/employee-page/employee-page.component').then( m => m.EmployeePageComponent),
    children: [
      {
        path: 'info',
        loadComponent: () => import('./garage-page/employees-tab/employee-page/employee-info-tab/employee-info-tab.component').then( m => m.EmployeeInfoTabComponent)
      },
      {
        path: 'activities',
        loadComponent: () => import('./garage-page/employees-tab/employee-page/employee-activities-tab/employee-activities-tab.component').then( m => m.EmployeeActivitiesTabComponent),
      },
      {
        path: '**',
        redirectTo: 'info',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
