import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private router: Router,
  ) {}

  isLoggedIn() {

    // If no token
    if (localStorage.getItem('token') === null) {
      return this.router.createUrlTree(['/auth']);
    } else {
      // If token exists
      return true;
    }

  }

  isNotLoggedIn() {
    // If no token
    if (localStorage.getItem('token') === null) {
      return true;
    } else {
      // If token exists
      return this.router.createUrlTree(['/pages']);
    }
  }

}

export const isNotLoggedIn: CanMatchFn = () => {
  return inject(AuthGuard).isNotLoggedIn();
};

export const isLoggedIn: CanMatchFn = () => {
  return inject(AuthGuard).isLoggedIn();
};
