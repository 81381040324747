import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {

    theme: string | null = null;

    isLoadingTheme = false;

    constructor(@Inject(DOCUMENT) private document: Document) {

      // Get theme and set it.
      this.theme = localStorage.getItem('theme')

      // If no theme is set, set the default theme
      if (!this.theme) {
        this.theme = 'mdc-light-indigo';
        localStorage.setItem('theme', 'mdc-light-indigo');
      };

      this.switchTheme(this.theme);

    }

    public switchTheme(theme: string) {

        // Changing theme may take a while, so we set a loading state
        this.isLoadingTheme = true;

        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

        if (themeLink) {
          themeLink.href = theme + '.css';
          localStorage.setItem('theme', theme);
          this.theme = theme;
        }

        // After 1 second, we remove the loading state (this should be enough time for the theme to load)
        setTimeout(() => {
          this.isLoadingTheme = false;
        }, 1000);

    }
}
