import { I18nModule } from '@mgm-frontend-monorepo/transloco';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';


@Component({
  selector: 'ui-order-status-tag',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    I18nModule
  ],
  template: `
    <p-tag
      [value]="'orderStatuses.' + status | transloco"
      [styleClass]="textSizeLG ? 'text-lg' : ''"
      [severity]="
        status === 'PENDING' ? 'info' :
        status === 'IN_PROGRESS' ? 'warning' :
        status === 'COMPLETED' || status === 'ACCEPTED' ? 'success' :
        status === 'CANCELLED' || status === 'DECLINED' ? 'danger' :
        'danger'
      "
    ></p-tag>

  `,
  styles: [],
})
export class OrderStatusTagComponent {

  @Input() status!: string;
  @Input() textSizeLG = false;
}
