import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'ui-error-label',
  standalone: true,
  imports: [CommonModule],
  animations: [
    fadeInOnEnterAnimation({duration: 600})
  ],
  template: `
    <div>
      <span class="text-sm text-red-600 mt-2" [@fadeInOnEnter] *ngIf="status">{{ message }}</span>
    </div>
  `,
  styles: [
  ]
})
export class ErrorLabelComponent {

  @Input() status: boolean | null | undefined = false;
  @Input() message = '';

}
