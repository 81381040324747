import { PageLoaderComponent } from '@mgm-frontend-monorepo/ui';
import { ThemeService, ThemeModule } from '@mgm-frontend-monorepo/theme';
import { RouterModule } from '@angular/router';
import { Component } from '@angular/core';
import { I18nModule } from '@mgm-frontend-monorepo/transloco';
import { HttpClient } from '@angular/common/http';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    ThemeModule,
    PageLoaderComponent,
    ToastModule,
    ConfirmPopupModule
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoadingTranslation = true;

  constructor(
    public themeService: ThemeService,
    private primeCnf: PrimeNGConfig,
    private translocoPipe: TranslocoPipe,
    private translocoService: TranslocoService,
    private http: HttpClient,
  ) {

    // ! KEEP THIS IN ORDER TO LOAD TRANSLOCO AT STARTUP
    this.translocoPipe.transform('load');

    primeCnf.ripple = true;

    // ? wait for transloco load translation
    this.translocoService.events$.subscribe((e) => {
      if (e.type === 'translationLoadSuccess') {
        this.isLoadingTranslation = false;
      }
    });

    // Change primeng locale on lang change
    this.translocoService.langChanges$.subscribe(async (lang) => {

      this.http.get(`/assets/i18n/primeng-${lang}.json`).subscribe((r) => {
        this.primeCnf.setTranslation(r);
      });
    });
  }
}
