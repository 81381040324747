import { CreatedByUser } from "./created-by-user.interface";
import { Garage } from "./garage.interface";
import { Customer } from "./mgm-customer.interface";
import { TyreOrderItem } from "./mgm-order.interface";

export enum MgmVehicleFuelType {
  PETROL,
  DIESEL,
  MIXED,
  HYDROGEN,
  CNG,
  ELECTRIC,
  LPG,
  PETROL_ELECTRIC,
  DIESEL_ELECTRIC,
  PETROL_CNG,
  PETROL_LNG,
  PETROL_LPG,
  PETROL_ETHANOL,
  PETROL_METHANOL,
  DIESEL_LPG,
  PETROL_ETHANOL_ELECTRIC,
  ETHANOL,
  PETROL_ETHANOL_CNG,
  UNKNOWN,
}

export interface Vehicle {
  id: string;
  vin?: string;
  vehicleVin: string;
  tecDocManufacturerId: number;
  tecDocModelId: number;
  tecDocCarId: number;
  manufacturer: string;
  model: string;
  vehicleName: string;
  plateNumber?: string;
  plateCountryCode?: string;
  yearOfManufacture?: number;
  engineDisplacement?: number;
  kilowatts?: number;
  fuelType?: MgmVehicleFuelType;
  customer: Customer;
  customerId?: string;

  createdBy?: CreatedByUser;
  createdByEmployee?: CreatedByUser;
  createdAt: string | Date;
  updatedAt: string;

  // Fields generated by the frontend for UX purposes
  isDeleting?: boolean;
}

export interface VehicleOdometerReading {
  id: string;
  kilometers: number;
  createdAt: string | Date;
  garage: Garage;
  createdByEmployee?: CreatedByUser;
}

export interface VehicleTyre {
  id: string,
  brand: string,
  name: string,
  width: number,
  height: number,
  diameter: number,
  constructionType: string,
  loadIndex: number,
  speedIndex: string,
  treadDepth: number,
  dimension: string,
  ean: string,
  season: string,
  noise: number,
  wet: string,
  grip: string,
  maxityreId: number,
  tyreImageId: number,
  brandImageName: string,
  createdAt: string,
  updatedAt: string,
  wornOut: boolean,
  wornOutAt: string,
  mounted: boolean,
  lastMountedPosition: 'FRONT_LEFT' | 'FRONT_RIGHT' | 'REAR_LEFT' | 'REAR_RIGHT',
  mountedAt: string,
  vehicleId: string,
  vehicle?: Vehicle,
  createdByEmployee?: CreatedByUser,

  location?: string,
  storedTyreId?: string,

  treadDepthChangeRecords: VehicleTyreTreadDepthChangeRecord[],
  tyreOrderItem?: TyreOrderItem,

  storedTyres?: unknown[];

  // Fields generated by the frontend for UX purposes
  isSettingWornOut?: boolean,
  isDeleting?: boolean,
}

export interface VehicleTyreMountRecord {
  id: string,
  vehicleId: string,
  actionType: 'MOUNT' | 'DISMOUNT',
  position: 'FRONT_LEFT' | 'FRONT_RIGHT' | 'REAR_LEFT' | 'REAR_RIGHT',
  treadDepth: number,
  createdAt: string,
  createdByEmployee?: CreatedByUser
  tyre: VehicleTyre,
}

export interface VehicleTyreTreadDepthChangeRecord {
  id: string,
  treadDepth: number,
  createdAt: string,
  createdByEmployee?: CreatedByUser
}
