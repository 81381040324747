import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService } from '../theme.service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'theme-switcher',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  template: `
    <button
      pButton
      pRipple
      type="button"
      [icon]="themeService.theme === 'mdc-light-indigo' ? 'pi pi-moon' : 'pi pi-sun'"
      [ngClass]="
        {
          'bg-white': themeService.theme === 'mdc-dark-indigo',
          'bg-dark': themeService.theme === 'mdc-light-indigo'
        }
      "
      (click)="themeService.theme === 'mdc-light-indigo' ? themeService.switchTheme('mdc-dark-indigo') : themeService.switchTheme('mdc-light-indigo')"
      class="p-button-rounded"
    ></button>
  `,
})
export class ThemeSwitcherComponent {

  constructor(public themeService: ThemeService) {}

}
