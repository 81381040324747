import { I18nModule } from '@mgm-frontend-monorepo/transloco';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';


@Component({
  selector: 'ui-role-tag',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    I18nModule
  ],
  template: `
    <p-tag
      [value]="'roles.' + role | transloco"
      [severity]="
        role === 'ADMIN' ? 'danger' :
        role === 'MANAGER' ? 'warning' :
        role === 'SECRETARY' ? 'info' :
        'success'
      "
    ></p-tag>

  `,
  styles: [],
})
export class RoleTagComponent {

  @Input() role!: string;
}
