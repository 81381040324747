import { Route } from '@angular/router';
import { isLoggedIn, isNotLoggedIn } from './auth/auth.guard';
import { pagesRoutes } from './pages/pages.routes';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadComponent: () => import('./auth/auth.component').then(m => m.AuthComponent),
    canMatch: [isNotLoggedIn]
  },
  {
    path: 'pages',
    loadComponent: () => import('./pages/pages.component').then(m => m.PagesComponent),
    canMatch: [isLoggedIn],
    children: pagesRoutes
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'auth',
  }
];
