export interface Address {
  unitNumber: string;
  street: string;
  city: string | null;
  postCode: number | null;
  country: string | null;
  countryCode: string | null;
  longitude: number;
  latitude: number;
  text?: string;
};
